var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2","outlined":""}},[_c('base-expansion-panel',{attrs:{"inputs":_vm.shukkaZumiAnkenList.inputs,"reactiveVar":_vm.reactiveVar}},[_c('base-form',{attrs:{"searchBtn":true,"resetBtn":true,"inputs":_vm.shukkaZumiAnkenList.inputs,"reactiveVar":_vm.reactiveVar},on:{"submit-form":_vm.handleSubmit}})],1),_c('v-card-subtitle',[_vm._v(_vm._s(`${_vm.labels.SHUKKA_DATE}: ${_vm.shippingDateFromTo.from ?? ''} 〜 ${_vm.shippingDateFromTo.to ?? ''}`))]),_c('base-table',{attrs:{"headers":_vm.shukkaZumiAnkenList.headers,"items":_vm.shukkaZumiAnkenItems,"columnName":[
      'item.comment_count',
      'item.bikou_list',
      'item.hikiwatashiCancel',
      'item.shorui',
      'item.total_weight',
      'item.tracking_number',
      'item.edit',
    ],"itemKey":_vm.itemKey,"itemClass":_vm.itemClass,"multiSort":"","showSelect":"","minWidth":"1800"},on:{"row-selected":(selected) => {
        this.rowSelected = selected;
      }},scopedSlots:_vm._u([{key:`item.comment_count`,fn:function({ item }){return [_c('lazy',[_c('v-btn',{attrs:{"color":"primary","x-small":"","align":"center","justify":"center"},on:{"click":function($event){_vm.getDataShukkaZumiAnkenCommentActions([item.shipping_seq_number]);
            _vm.rowComment = item.shipping_seq_number;
            _vm.showModal = true;}}},[_vm._v("開く")])],1),_c('div',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"comment-text"},[_vm._v(" "+_vm._s(item.comment_count ? item.comment_count + '件' : '')+" "+_vm._s(item.last_comment_date)+" ")])])]}},{key:`item.total_weight`,fn:function({ item }){return [_c('td',{staticStyle:{"text-align":"right"}},[(item.total_weight != null)?_c('span',[_vm._v(_vm._s(item.total_weight.commaString(' kg')))]):_vm._e()])]}},{key:`item.bikou_list`,fn:function({ item }){return [(item.editing)?_c('v-textarea',{attrs:{"filled":"","hide-details":"true","value":item.bikou_list},on:{"change":function($event){return _vm.editShukkaZumiAnkenItemsActions([
            {
              shipping_seq_number: item.shipping_seq_number,
              new_bikou_list: $event,
            },
          ])}}}):(item.bikou_list)?_c('div',{staticStyle:{"min-width":"100px"}},_vm._l((item.bikou_list.split('\n')),function(ele){return _c('span',{key:ele,staticStyle:{"line-break":"anywhere"}},[_vm._v(_vm._s(ele)),_c('br')])}),0):_vm._e()]}},{key:`item.hikiwatashiCancel`,fn:function({ item }){return [(_vm.role)?_c('div',[_c('lazy',[(!item.editing)?_c('v-btn',{attrs:{"color":"red accent-4 white--text","x-small":""},on:{"click":function($event){_vm.showDangerModal = true;
              _vm.showDangerConfirmModal(item);}}},[_vm._v("キャンセル")]):_vm._e()],1)],1):_vm._e()]}},{key:`item.shorui`,fn:function({ item }){return [_c('lazy',[_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.downloadMergedExcels({
              format: 'url',
              shippingSeqNumbers: [item.shipping_seq_number],
              attachmentsIncluded: true,
            })}}},[_vm._v("dl")])],1)]}},{key:`item.tracking_number`,fn:function({ item }){return [(item.editing)?_c('v-textarea',{attrs:{"filled":"","hide-details":"true","value":item.tracking_number},on:{"change":function($event){return _vm.editShukkaZumiAnkenItemsActions([
            {
              shipping_seq_number: item.shipping_seq_number,
              new_tracking_number: $event,
            },
          ])}}}):_c('span',{staticClass:"wrap-text"},[_vm._v(_vm._s(item.tracking_number))])]}},{key:`item.edit`,fn:function({ item }){return [_c('lazy',[(_vm.role)?_c('div',[(item.editing)?_c('v-btn',{attrs:{"text":"","icon":"","color":"green lighten-2"},on:{"click":function($event){return _vm.updateShukkaZumiAnkenItemsActions([
                {
                  shippingSeqNumber: item.shipping_seq_number,
                  bikou: item.new_bikou_list,
                  trackingNumber: item.new_tracking_number,
                },
              ])}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-content-save")])],1):_c('v-btn',{attrs:{"text":"","icon":"","color":"blue lighten-2"},on:{"click":function($event){return _vm.editShukkaZumiAnkenItemsActions([
                {
                  shipping_seq_number: item.shipping_seq_number,
                  editing: true,
                },
              ])}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-pencil-box")])],1),(item.editing)?_c('v-btn',{attrs:{"text":"","icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.editShukkaZumiAnkenItemsActions([
                {
                  shipping_seq_number: item.shipping_seq_number,
                  editing: false,
                  new_bikou_list: item.bikou_list,
                  new_tracking_number: item.tracking_number,
                },
              ])}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close-circle")])],1):_vm._e()],1):_vm._e()])]}}],null,true)}),_c('v-card-actions',{staticClass:"justify-center pt-0 pb-4"},[_c('csv-result-dl',{attrs:{"disabled":_vm.shukkaZumiAnkenItems.length === 0,"item-key":_vm.itemKey,"row-selected":_vm.rowSelected}})],1),_c('commentModal',{attrs:{"show-modal":_vm.showModal,"items":_vm.commentItems,"role":_vm.role},on:{"close":_vm.closeModal,"commentRegist":_vm.commentRegist}}),_c('DangerConfirmModal',{attrs:{"show-modal":_vm.showDangerModal,"message":_vm.dangerMessage,"confirmText":_vm.dangerConfirmText},on:{"click:confirm":_vm.handleDangerConfirm,"click:close":_vm.handleClose}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }