var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2","outlined":""}},[_c('base-expansion-panel',{attrs:{"inputs":_vm.inputs,"reactiveVar":_vm.reactiveVar}},[_c('base-form',{attrs:{"searchBtn":true,"resetBtn":true,"inputs":_vm.inputs,"reactiveVar":_vm.reactiveVar,"numberOfCols":3},on:{"submit-form":_vm.getDataShukkaHikiWatashiItemsActions}})],1),_c('base-table',{attrs:{"headers":_vm.shukkaHikiWatashiList.headers,"items":_vm.shukkaHikiWatashiItems,"columnName":[
      'item.bikou_list',
      'item.comment_count',
      'item.total_weight',
      'item.shukkaShorui',
      'item.operator_documents',
      'item.picking_completed_flag',
      'item.arms_shipment_process_confirmed_flag',
      'item.center_documents',
      'item.center_attached_docs_confirmed_flag',
      'item.shipped_confirmed_flag',
      'item.tracking_number',
      'item.edit',
      'item.shipping_label_link',
      'item.shukkaShoruiPrint',
    ],"itemClass":_vm.itemClass,"itemKey":_vm.itemKey,"multiSort":"","showSelect":"","minWidth":"2200"},on:{"row-selected":(selected) => {
        this.rowSelected = selected;
      }},scopedSlots:_vm._u([{key:`item.bikou_list`,fn:function({ item }){return [(item.editing)?_c('v-textarea',{attrs:{"filled":"","hide-details":"true","value":item.bikou_list},on:{"change":function($event){return _vm.editShukkaHikiWatashiItemsActions([
            {
              shipping_seq_number: item.shipping_seq_number,
              new_bikou_list: $event,
            },
          ])}}}):(item.bikou_list)?_c('div',{staticStyle:{"min-width":"100px"}},_vm._l((item.bikou_list.split('\n')),function(ele){return _c('span',{key:ele,staticStyle:{"line-break":"anywhere"}},[_vm._v(_vm._s(ele)),_c('br')])}),0):_vm._e()]}},{key:`item.total_weight`,fn:function({ item }){return [_c('td',{staticStyle:{"text-align":"right"}},[(item.total_weight != null)?_c('span',[_vm._v(_vm._s(item.total_weight.commaString(' kg')))]):_vm._e()])]}},{key:`item.comment_count`,fn:function({ item }){return [_c('lazy',[_c('v-btn',{attrs:{"color":"primary","x-small":"","align":"center","justify":"center"},on:{"click":function($event){_vm.getDataShukkaHikiWatashiCommentActions([item.shipping_seq_number]);
            _vm.rowComment = item.shipping_seq_number;
            _vm.showModal = true;}}},[_vm._v("開く")])],1),_c('div',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"comment-text"},[_vm._v(" "+_vm._s(item.comment_count ? item.comment_count + '件' : '')+" "+_vm._s(item.last_comment_date)+" ")])])]}},{key:`item.shukkaShorui`,fn:function({ item }){return [_c('lazy',[_c('div',{staticClass:"d-flex justify-center align-center"},[(!item.editing)?_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.downloadMergedExcels({
                format: 'url',
                shippingSeqNumbers: [item.shipping_seq_number],
                attachmentsIncluded: true,
              })}}},[_vm._v(" DL ")]):_vm._e(),(item.no_battery_img_models && item.no_battery_img_models.length > 0)?_c('tool-tip',{attrs:{"message":item.no_battery_img_models?.join(',<br>'),"classes":"mx-2"}}):_vm._e()],1)])]}},{key:`item.shukkaShoruiPrint`,fn:function({ item }){return [_c('lazy',[_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.downloadPrintExcel({
              shippingSeqNumbers: [item.shipping_seq_number],
            })}}},[_vm._v("印刷")])],1)]}},{key:`item.shipping_label_link`,fn:function({ item }){return [_c('lazy',[(item.shipping_label_link)?_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.downloadFile({ key: item.shipping_label_link })}}},[_vm._v("dl")]):_vm._e()],1)]}},{key:`item.operator_documents`,fn:function({ item }){return [(_vm.role)?_c('div',[_c('lazy',[_c('div',[(!item.editing)?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){_vm.uploadOperatorDocumentsModal = true;
                _vm.uploadingRow = item;}}},[_vm._v("UP ")]):_vm._e(),(!!item.operator_documents)?_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(!item.operator_documents ? '' : item.operator_documents.length > 0 ? 'x' + item.operator_documents.length : '')+" ")]):_vm._e()],1)])],1):_vm._e()]}},{key:`item.picking_completed_flag`,fn:function({ item }){return [_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('lazy',[(item.editing)?_c('v-checkbox',{key:item.picking_completed_flag,staticClass:"mt-0 pt-0",attrs:{"color":"primary","input-value":item.picking_completed_flag,"dense":"","hide-details":""},on:{"change":function($event){return _vm.editShukkaHikiWatashiItemsActions([
                  {
                    shipping_seq_number: item.shipping_seq_number,
                    new_picking_completed_flag: $event,
                  },
                ])}}}):_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"primary","input-value":item.picking_completed_flag,"dense":"","hide-details":"","disabled":""}})],1)],1)],1)]}},{key:`item.arms_shipment_process_confirmed_flag`,fn:function({ item }){return [_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('lazy',[(item.editing)?_c('v-checkbox',{key:item.arms_shipment_process_confirmed_flag,staticClass:"mt-0 pt-0",attrs:{"color":"primary","input-value":item.arms_shipment_process_confirmed_flag,"dense":"","hide-details":""},on:{"change":function($event){return _vm.editShukkaHikiWatashiItemsActions([
                  {
                    shipping_seq_number: item.shipping_seq_number,
                    new_arms_shipment_process_confirmed_flag: $event,
                  },
                ])}}}):_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"primary","input-value":item.arms_shipment_process_confirmed_flag,"dense":"","hide-details":"","disabled":""}})],1)],1)],1)]}},{key:`item.center_documents`,fn:function({ item }){return [(_vm.role)?_c('div',[_c('lazy',[_c('div',[(!item.editing)?_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){_vm.uploadCenterDocumentsModal = true;
                _vm.uploadingRow = item;}}},[_vm._v("up")]):_vm._e(),(!!item.center_documents)?_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(!item.center_documents ? '' : item.center_documents.length > 0 ? 'x' + item.center_documents.length : '')+" ")]):_vm._e()],1)])],1):_vm._e()]}},{key:`item.center_attached_docs_confirmed_flag`,fn:function({ item }){return [_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('lazy',[(item.editing)?_c('v-checkbox',{key:item.center_attached_docs_confirmed_flag,staticClass:"mt-0 pt-0",attrs:{"color":"primary","input-value":item.center_attached_docs_confirmed_flag,"dense":"","hide-details":""},on:{"change":function($event){return _vm.editShukkaHikiWatashiItemsActions([
                  {
                    shipping_seq_number: item.shipping_seq_number,
                    new_center_attached_docs_confirmed_flag: $event,
                  },
                ])}}}):_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"primary","input-value":item.center_attached_docs_confirmed_flag,"dense":"","hide-details":"","disabled":""}})],1)],1)],1)]}},{key:`item.shipped_confirmed_flag`,fn:function({ item }){return [_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('lazy',[(item.editing)?_c('v-checkbox',{key:item.shipped_confirmed_flag,staticClass:"mt-0 pt-0",attrs:{"color":"primary","input-value":item.shipped_confirmed_flag,"dense":"","hide-details":""},on:{"change":function($event){return _vm.editShukkaHikiWatashiItemsActions([
                  {
                    shipping_seq_number: item.shipping_seq_number,
                    new_shipped_confirmed_flag: $event,
                  },
                ])}}}):_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"primary","input-value":item.shipped_confirmed_flag,"dense":"","hide-details":"","disabled":""}})],1)],1)],1)]}},{key:`item.tracking_number`,fn:function({ item }){return [(item.editing)?_c('v-textarea',{attrs:{"filled":"","hide-details":"true","value":item.tracking_number},on:{"change":function($event){return _vm.editShukkaHikiWatashiItemsActions([
            {
              shipping_seq_number: item.shipping_seq_number,
              new_tracking_number: $event,
            },
          ])}}}):_c('span',[_vm._v(_vm._s(item.tracking_number))])]}},{key:`item.edit`,fn:function({ item }){return [_c('lazy',[(_vm.role)?_c('div',[(item.editing)?_c('v-btn',{attrs:{"text":"","icon":"","color":"green lighten-2"},on:{"click":function($event){return _vm.updateShukkaHikiWatashiItemsActions([
                {
                  shippingSeqNumber: item.shipping_seq_number,
                  bikou: item.new_bikou_list,
                  pickingCompletedFlag: item.new_picking_completed_flag,
                  armsConfirmedFlag: item.new_arms_shipment_process_confirmed_flag,
                  centerConfirmedFlag: item.new_center_attached_docs_confirmed_flag,
                  shippedConfirmedFlag: item.new_shipped_confirmed_flag,
                  shippingCheckUserName: item.shipping_check_user_name,
                  shippingDatetime: item.shipping_datetime,
                  trackingNumber: item.new_tracking_number,
                  memberNo: item.member_id,
                },
              ])}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-content-save")])],1):_c('v-btn',{attrs:{"text":"","icon":"","color":"blue lighten-2"},on:{"click":function($event){return _vm.editShukkaHikiWatashiItemsActions([
                {
                  shipping_seq_number: item.shipping_seq_number,
                  editing: true,
                },
              ])}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-pencil-box")])],1),(item.editing)?_c('v-btn',{attrs:{"text":"","icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.editShukkaHikiWatashiItemsActions([
                {
                  shipping_seq_number: item.shipping_seq_number,
                  editing: false,
                  new_bikou_list: item.bikou_list,
                  new_picking_completed_flag: item.picking_completed_flag,
                  new_arms_shipment_process_confirmed_flag: item.arms_shipment_process_confirmed_flag,
                  new_center_attached_docs_confirmed_flag: item.center_attached_docs_confirmed_flag,
                  new_shipped_confirmed_flag: item.shipped_confirmed_flag,
                  new_tracking_number: item.tracking_number,
                },
              ])}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close-circle")])],1):_vm._e()],1):_vm._e()])]}}],null,true)}),_c('commentModal',{attrs:{"show-modal":_vm.showModal,"items":_vm.commentItems,"role":_vm.role},on:{"close":_vm.closeModal,"commentRegist":_vm.commentRegist}}),_c('v-card-actions',{staticClass:"justify-center mb-3"},[_c('v-btn',{attrs:{"disabled":_vm.rowSelected.length === 0,"color":"teal darken-2 white--text"},on:{"click":function($event){_vm.downloadMergedExcels({
          format: 'url',
          shippingSeqNumbers: _vm.rowSelected.map((row) => row.shipping_seq_number),
        })}}},[_vm._v(_vm._s(_vm.labels.BTN_SHUKKA_SHORUI_DL)+" "),_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-arrow-down-bold")])],1),_c('v-btn',{attrs:{"disabled":_vm.rowSelected.length === 0,"color":"primary"},on:{"click":function($event){_vm.downloadPrintExcel({
          shippingSeqNumbers: _vm.rowSelected.map((row) => row.shipping_seq_number),
        })}}},[_vm._v("一括印刷用Excel DL "),_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-printer")])],1),_c('v-btn',{attrs:{"color":"success darken-1","disabled":_vm.rowSelected.length === 0},on:{"click":_vm.downloadPreparedShipmentListCSV}},[_vm._v("センター出荷準備リスト出力 "),_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-arrow-down-bold")])],1),_c('csv-result-dl',{attrs:{"disabled":_vm.shukkaHikiWatashiItems.length === 0,"item-key":_vm.itemKey,"row-selected":_vm.rowSelected}})],1),_c('UploadOperatorDocumentsModal',{attrs:{"parent":_vm.$data}}),_c('UploadCenterDocumentsModal',{attrs:{"parent":_vm.$data}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }