var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2","outlined":""}},[_c('base-expansion-panel',{attrs:{"inputs":_vm.minyukinAnkenLists.inputs,"reactiveVar":_vm.reactiveVar}},[_c('base-form',{attrs:{"searchBtn":true,"resetBtn":true,"inputs":_vm.minyukinAnkenLists.inputs,"reactiveVar":_vm.reactiveVar},on:{"submit-form":function($event){return _vm.getDataMinyukinAnkenItemsActions(_vm.reactiveVar)}}})],1),_c('base-table',{attrs:{"headers":_vm.minyukinAnkenLists.headers,"items":_vm.minyukinAnkenItems,"columnName":[
      'item.bikou',
      'item.total_quantity',
      'item.total_weight',
      'item.shipment_pending_flag',
      'item.edit',
    ],"itemKey":_vm.itemKey,"itemClass":_vm.itemClass,"multiSort":""},scopedSlots:_vm._u([{key:`item.bikou`,fn:function({ item }){return [(item.editing)?_c('v-textarea',{attrs:{"filled":"","hide-details":"true","value":item.bikou},on:{"change":function($event){return _vm.editMinyuukinAnkenItemsActions([{ packing_no: item.packing_no, new_bikou: $event }])}}}):_c('span',{staticClass:"wrap-text"},[_vm._v(_vm._s(item.bikou))])]}},{key:`item.total_quantity`,fn:function({ item }){return [_c('td',{staticStyle:{"text-align":"right"}},[(item.total_quantity != null)?_c('span',[_vm._v(_vm._s(item.total_quantity.commaString()))]):_vm._e()])]}},{key:`item.total_weight`,fn:function({ item }){return [_c('td',{staticStyle:{"text-align":"right"}},[(item.total_weight != null)?_c('span',[_vm._v(_vm._s(item.total_weight.commaString(' kg')))]):_vm._e()])]}},{key:`item.shipment_pending_flag`,fn:function({ item }){return [_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('lazy',[(item.editing)?_c('v-checkbox',{key:item.shipment_pending_flag,staticClass:"mt-0 pt-0",attrs:{"color":"primary","input-value":item.shipment_pending_flag,"dense":"","hide-details":""},on:{"change":function($event){return _vm.editMinyuukinAnkenItemsActions([
                  {
                    packing_no: item.packing_no,
                    new_shipment_pending_flag: $event,
                  },
                ])}}}):_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"primary","input-value":item.shipment_pending_flag,"dense":"","hide-details":"","disabled":""}})],1)],1)],1)]}},{key:`item.edit`,fn:function({ item }){return [_c('lazy',[(_vm.role)?_c('div',[(item.editing)?_c('v-btn',{attrs:{"text":"","icon":"","color":"green lighten-2"},on:{"click":function($event){return _vm.updateMinyuukinAnkenItemsActions([
                {
                  packingNo: item.packing_no,
                  bikou: item.new_bikou,
                  shipmentPendingFlag: item.new_shipment_pending_flag,
                },
              ])}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-content-save")])],1):_c('v-btn',{attrs:{"text":"","icon":"","color":"blue lighten-2"},on:{"click":function($event){return _vm.editMinyuukinAnkenItemsActions([{ packing_no: item.packing_no, editing: true }])}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-pencil-box")])],1),(item.editing)?_c('v-btn',{attrs:{"text":"","icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.editMinyuukinAnkenItemsActions([
                {
                  packing_no: item.packing_no,
                  editing: false,
                  new_bikou: item.bikou,
                  new_shipment_pending_flag: item.shipment_pending_flag,
                },
              ])}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close-circle")])],1):_vm._e()],1):_vm._e()])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }