<template>
  <v-card elevation="2" outlined class="mx-auto">
    <base-expansion-panel :inputs="minyukinAnkenLists.inputs" :reactiveVar="reactiveVar">
      <base-form
        :searchBtn="true"
        :resetBtn="true"
        :inputs="minyukinAnkenLists.inputs"
        :reactiveVar="reactiveVar"
        @submit-form="getDataMinyukinAnkenItemsActions(reactiveVar)"
      >
      </base-form>
    </base-expansion-panel>
    <base-table
      :headers="minyukinAnkenLists.headers"
      :items="minyukinAnkenItems"
      :columnName="[
        'item.bikou',
        'item.total_quantity',
        'item.total_weight',
        'item.shipment_pending_flag',
        'item.edit',
      ]"
      :itemKey="itemKey"
      :itemClass="itemClass"
      multiSort
    >
      <template v-slot:[`item.bikou`]="{ item }">
        <v-textarea
          v-if="item.editing"
          filled
          hide-details="true"
          :value="item.bikou"
          @change="editMinyuukinAnkenItemsActions([{ packing_no: item.packing_no, new_bikou: $event }])"
        ></v-textarea>
        <span class="wrap-text" v-else>{{ item.bikou }}</span>
      </template>
      <template v-slot:[`item.total_quantity`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.total_quantity != null">{{ item.total_quantity.commaString() }}</span>
        </td>
      </template>
      <template v-slot:[`item.total_weight`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.total_weight != null">{{ item.total_weight.commaString(' kg') }}</span>
        </td>
      </template>
      <template v-slot:[`item.shipment_pending_flag`]="{ item }">
        <v-row>
          <v-col class="d-flex justify-center">
            <lazy>
              <v-checkbox
                v-if="item.editing"
                class="mt-0 pt-0"
                color="primary"
                :input-value="item.shipment_pending_flag"
                dense
                hide-details
                :key="item.shipment_pending_flag"
                @change="
                  editMinyuukinAnkenItemsActions([
                    {
                      packing_no: item.packing_no,
                      new_shipment_pending_flag: $event,
                    },
                  ])
                "
              ></v-checkbox>
              <v-checkbox
                v-else
                class="mt-0 pt-0"
                color="primary"
                :input-value="item.shipment_pending_flag"
                dense
                hide-details
                disabled
              ></v-checkbox>
            </lazy>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <lazy>
          <div v-if="role">
            <v-btn
              v-if="item.editing"
              text
              icon
              color="green lighten-2"
              @click="
                updateMinyuukinAnkenItemsActions([
                  {
                    packingNo: item.packing_no,
                    bikou: item.new_bikou,
                    shipmentPendingFlag: item.new_shipment_pending_flag,
                  },
                ])
              "
            >
              <v-icon large>mdi-content-save</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              icon
              color="blue lighten-2"
              @click="editMinyuukinAnkenItemsActions([{ packing_no: item.packing_no, editing: true }])"
            >
              <v-icon large>mdi-pencil-box</v-icon>
            </v-btn>
            <v-btn
              v-if="item.editing"
              text
              icon
              color="red lighten-2"
              @click="
                editMinyuukinAnkenItemsActions([
                  {
                    packing_no: item.packing_no,
                    editing: false,
                    new_bikou: item.bikou,
                    new_shipment_pending_flag: item.shipment_pending_flag,
                  },
                ])
              "
            >
              <v-icon large>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </lazy>
      </template>
    </base-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'MinyukinAnkenLists',
  components: {
    lazy: () => import(/* webpackChunkName: "Lazy" */ '@/components/ui/Lazy.vue'),
  },
  data() {
    return {
      itemKey: 'packing_no',
      role: false,
    };
  },
  computed: {
    ...mapGetters({
      minyukinAnkenLists: 'defined/minyukinAnkenLists',
      reactiveVar: 'minyukinAnken/reactiveVar',
      minyukinAnkenItems: 'minyukinAnken/minyukinAnkenItems',
      path: 'defined/pathNames',
      user: 'defined/user',
    }),
  },
  async created() {
    this.role = await this.getRole({
      page: this.path.MINYUKIN_ANKEN_LISTS,
      authority: this.user.authority,
    });
    await this.getDataMinyukinAnkenItemsActions(this.reactiveVar);
  },
  methods: {
    ...mapActions({
      getDataMinyukinAnkenItemsActions: 'minyukinAnken/getDataMinyukinAnkenItemsActions',
      editMinyuukinAnkenItemsActions: 'minyukinAnken/editMinyuukinAnkenItemsActions',
      updateMinyuukinAnkenItemsActions: 'minyukinAnken/updateMinyuukinAnkenItemsActions',
      getRole: 'common/getRole',
    }),
    itemClass(item) {
      if (item.editing) {
        return 'highlight-green not-change-hover-color';
      }
      return '';
    },
  },
};
</script>
<style>
.wrap-text {
  white-space: pre;
}
</style>
