<template>
  <v-card elevation="2" outlined class="mx-auto">
    <base-expansion-panel :inputs="shukkaZumiAnkenList.inputs" :reactiveVar="reactiveVar">
      <base-form
        :searchBtn="true"
        :resetBtn="true"
        :inputs="shukkaZumiAnkenList.inputs"
        :reactiveVar="reactiveVar"
        @submit-form="handleSubmit"
      >
      </base-form>
    </base-expansion-panel>
    <v-card-subtitle>{{
      `${labels.SHUKKA_DATE}: ${shippingDateFromTo.from ?? ''} 〜 ${shippingDateFromTo.to ?? ''}`
    }}</v-card-subtitle>
    <base-table
      :headers="shukkaZumiAnkenList.headers"
      :items="shukkaZumiAnkenItems"
      :columnName="[
        'item.comment_count',
        'item.bikou_list',
        'item.hikiwatashiCancel',
        'item.shorui',
        'item.total_weight',
        'item.tracking_number',
        'item.edit',
      ]"
      :itemKey="itemKey"
      :itemClass="itemClass"
      multiSort
      showSelect
      minWidth="1800"
      @row-selected="
        (selected) => {
          this.rowSelected = selected;
        }
      "
    >
      <template v-slot:[`item.comment_count`]="{ item }">
        <lazy>
          <v-btn
            color="primary"
            x-small
            align="center"
            justify="center"
            @click="
              getDataShukkaZumiAnkenCommentActions([item.shipping_seq_number]);
              rowComment = item.shipping_seq_number;
              showModal = true;
            "
            >開く</v-btn
          >
        </lazy>
        <div align="center" justify="center">
          <div class="comment-text">
            {{ item.comment_count ? item.comment_count + '件' : '' }}
            {{ item.last_comment_date }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.total_weight`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.total_weight != null">{{ item.total_weight.commaString(' kg') }}</span>
        </td>
      </template>
      <template v-slot:[`item.bikou_list`]="{ item }">
        <v-textarea
          v-if="item.editing"
          filled
          hide-details="true"
          :value="item.bikou_list"
          @change="
            editShukkaZumiAnkenItemsActions([
              {
                shipping_seq_number: item.shipping_seq_number,
                new_bikou_list: $event,
              },
            ])
          "
        ></v-textarea>
        <div v-else-if="item.bikou_list" style="min-width: 100px">
          <span style="line-break: anywhere" v-for="ele in item.bikou_list.split('\n')" :key="ele"
            >{{ ele }}<br
          /></span>
        </div>
      </template>
      <template v-slot:[`item.hikiwatashiCancel`]="{ item }">
        <div v-if="role">
          <lazy>
            <v-btn
              color="red accent-4 white--text"
              x-small
              v-if="!item.editing"
              @click="
                showDangerModal = true;
                showDangerConfirmModal(item);
              "
              >キャンセル</v-btn
            >
          </lazy>
        </div>
      </template>
      <template v-slot:[`item.shorui`]="{ item }">
        <lazy>
          <v-btn
            color="primary"
            x-small
            @click="
              downloadMergedExcels({
                format: 'url',
                shippingSeqNumbers: [item.shipping_seq_number],
                attachmentsIncluded: true,
              })
            "
            >dl</v-btn
          >
        </lazy>
      </template>
      <template v-slot:[`item.tracking_number`]="{ item }">
        <v-textarea
          v-if="item.editing"
          filled
          hide-details="true"
          :value="item.tracking_number"
          @change="
            editShukkaZumiAnkenItemsActions([
              {
                shipping_seq_number: item.shipping_seq_number,
                new_tracking_number: $event,
              },
            ])
          "
        ></v-textarea>
        <span class="wrap-text" v-else>{{ item.tracking_number }}</span>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <lazy>
          <div v-if="role">
            <v-btn
              v-if="item.editing"
              text
              icon
              color="green lighten-2"
              @click="
                updateShukkaZumiAnkenItemsActions([
                  {
                    shippingSeqNumber: item.shipping_seq_number,
                    bikou: item.new_bikou_list,
                    trackingNumber: item.new_tracking_number,
                  },
                ])
              "
            >
              <v-icon large>mdi-content-save</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              icon
              color="blue lighten-2"
              @click="
                editShukkaZumiAnkenItemsActions([
                  {
                    shipping_seq_number: item.shipping_seq_number,
                    editing: true,
                  },
                ])
              "
            >
              <v-icon large>mdi-pencil-box</v-icon>
            </v-btn>
            <v-btn
              v-if="item.editing"
              text
              icon
              color="red lighten-2"
              @click="
                editShukkaZumiAnkenItemsActions([
                  {
                    shipping_seq_number: item.shipping_seq_number,
                    editing: false,
                    new_bikou_list: item.bikou_list,
                    new_tracking_number: item.tracking_number,
                  },
                ])
              "
            >
              <v-icon large>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </lazy>
      </template>
    </base-table>
    <v-card-actions class="justify-center pt-0 pb-4">
      <csv-result-dl
        :disabled="shukkaZumiAnkenItems.length === 0"
        :item-key="itemKey"
        :row-selected="rowSelected"
      ></csv-result-dl>
    </v-card-actions>
    <commentModal
      :show-modal="showModal"
      :items="commentItems"
      :role="role"
      @close="closeModal"
      @commentRegist="commentRegist"
    >
    </commentModal>
    <DangerConfirmModal
      :show-modal="showDangerModal"
      :message="dangerMessage"
      :confirmText="dangerConfirmText"
      @click:confirm="handleDangerConfirm"
      @click:close="handleClose"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  components: {
    commentModal: () => import(/* webpackChunkName: "commentModal" */ '@/components/ui/commentModal.vue'),
    DangerConfirmModal: () =>
      import(/* webpackChunkName: "DangerConfirmModal" */ '@/components/ui/DangerConfirmModal.vue'),
    lazy: () => import(/* webpackChunkName: "Lazy" */ '@/components/ui/Lazy.vue'),
  },
  name: 'shukkaZumiAnken',
  data() {
    return {
      itemKey: 'shipping_seq_number',
      showModal: false,
      rowComment: '',
      showDangerModal: false,
      dangerConfirmText: '',
      dangerMessage: '',
      role: false,
      rowSelected: [],
    };
  },
  computed: {
    ...mapGetters({
      shukkaZumiAnkenList: 'defined/shukkaZumiAnkenList',
      reactiveVar: 'shukkaZumiAnken/reactiveVar',
      shukkaZumiAnkenItems: 'shukkaZumiAnken/shukkaZumiAnkenItems',
      commentItems: 'shukkaZumiAnken/commentItems',
      path: 'defined/pathNames',
      messages: 'defined/messages',
      labels: 'defined/labels',
      user: 'defined/user',
    }),
    shippingDateFromTo() {
      const keys = Object.keys(this.reactiveVar);
      const hasInputs = keys
        .map((key) => {
          if (key !== 'shukkaDate') {
            if (this.reactiveVar[key]?.[0] || this.reactiveVar[key]?.[1]) {
              return 1;
            } else if (
              typeof this.reactiveVar[key] === 'string' &&
              this.reactiveVar[key] &&
              this.reactiveVar[key] !== ''
            ) {
              return 1;
            }
          }
        })
        .filter(Boolean)
        .reduce((a, b) => a + b, 0);

      const today = new Date();
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      const inputEndDate = new Date(this.reactiveVar.shukkaDate[1]);
      const from = this.reactiveVar?.shukkaDate?.[0]
        ? new Date(this.reactiveVar.shukkaDate[0])
        : !this.reactiveVar.shukkaDate[0] && this.reactiveVar.shukkaDate[1]
          ? new Date(inputEndDate.getFullYear(), inputEndDate.getMonth(), 1)
          : startOfMonth;
      const to = this.reactiveVar?.shukkaDate?.[1] ? inputEndDate : endOfMonth;
      if (hasInputs > 0) {
        return {
          from: this.reactiveVar?.shukkaDate?.[0] ?? '2022/9/13',
          to: to.toLocaleDateString('ja-jp') ?? to,
        };
      }
      return {
        from: from?.toLocaleDateString().includes('Invalid') ? '' : from?.toLocaleDateString('ja-jp') ?? from,
        to: to.toLocaleDateString().includes('Invalid') ? '' : to.toLocaleDateString('ja-jp') ?? to,
      };
    },
  },
  async created() {
    this.role = await this.getRole({
      page: this.path.SHUKKA_ZUMI_ANKEN,
      authority: this.user.authority,
    });
    await this.getDataShukkaZumiAnkenItemsActions(this.reactiveVar);
  },
  methods: {
    ...mapActions({
      getDataShukkaZumiAnkenItemsActions: 'shukkaZumiAnken/getDataShukkaZumiAnkenItemsActions',
      getDataShukkaZumiAnkenCommentActions: 'shukkaZumiAnken/getDataShukkaZumiAnkenCommentActions',
      editShukkaZumiAnkenItemsActions: 'shukkaZumiAnken/editShukkaZumiAnkenItemsActions',
      updateShukkaZumiAnkenItemsActions: 'shukkaZumiAnken/updateShukkaZumiAnkenItemsActions',
      registCommentActions: 'shukkaZumiAnken/registCommentActions',
      downloadMergedExcels: 'api/downloadMergedExcels',
      getRole: 'common/getRole',
    }),
    ...mapMutations({}),
    async commentRegist(val, noticeTeams = []) {
      await this.registCommentActions({
        comment: val,
        noticeTeams: noticeTeams,
        shippingSeqNumber: this.rowComment,
      });
      this.getDataShukkaZumiAnkenItemsActions(this.reactiveVar);
      this.closeModal();
    },
    closeModal() {
      this.rowComment = '';
      this.commentItems = [];
      this.showModal = false;
    },
    itemClass(item) {
      if (item.editing) {
        return 'highlight-green not-change-hover-color';
      }
      return '';
    },
    showDangerConfirmModal(info) {
      // this.showDangerModal = true;
      this.dangerConfirmText = info.shipping_seq_number;
      this.dangerMessage = this.messages.SHIPMENT_CANCEL_CONFIRM;
    },
    async handleDangerConfirm() {
      await this.updateShukkaZumiAnkenItemsActions([
        {
          shippingSeqNumber: this.dangerConfirmText,
          cancelFlag: 1,
        },
      ]);
      this.showDangerModal = false;
    },
    handleClose() {
      this.showDangerModal = false;
      this.dangerConfirmText = '';
    },
    handleSubmit() {
      const submit = JSON.parse(JSON.stringify(this.reactiveVar));
      const date = (d) => {
        const a = new Date(d.replaceAll('/', '-'));
        return new Date(a - a.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0];
      };
      submit.shukkaDate[0] = date(this.shippingDateFromTo.from);
      submit.shukkaDate[1] = date(this.shippingDateFromTo.to);
      this.getDataShukkaZumiAnkenItemsActions(submit);
    },
  },
};
</script>
<style>
.wrap-text {
  white-space: pre;
}
.comment-text {
  font-size: xx-small;
}
</style>
